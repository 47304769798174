import React from "react"

import styled from "styled-components"
import { Section } from "../Section"
import { TeamMember } from "../TeamMember"
import { Grid, Media } from "../../utilities"

const TeamGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  justify-items: center;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

export const TeamMembers = ({ members }) => (
  <Section modifiers={"default"}>
    <TeamGrid>
      {members.map(member => (
        <TeamMember
          name={member.node.title}
          title={member.node.acf.job_title}
          isOwner={member.node.acf.is_owner}
          pic={
            member.node.featured_media
              ? member.node.featured_media.source_url
              : null
          }
        >
          <div dangerouslySetInnerHTML={{ __html: member.node.content }}></div>
        </TeamMember>
      ))}
    </TeamGrid>
  </Section>
)
