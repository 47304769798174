import React from "react"
import styled from "styled-components"
import * as moment from "moment"

import footerimg from "../../../images/footer-logo.jpg"

import { Color, Wrapper, Flex, Media } from "../../utilities"

const StyledFooter = styled.div`
  color: ${Color.white};
  background-color: ${Color.red};
  padding: 2rem 0 3rem 0;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;

  h4 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;
    color: ${Color.white};
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      color: ${Color.white};
    }
  }

  ${Flex} {
    margin-left: 20%;
    margin-right: 20%;
    justify-content: space-between;

    ${Media.below.tablet`
      display: block;
    `}
  }
`

const SiteMeta = styled.div`
  flex: 0 !important;
  flex-basis: auto !important;
  ${Media.below.tablet`
      text-align: center;
    `}
`
const Bbb = styled.div`
  flex: 0 !important;
  flex-basis: auto !important;
  text-align: right;
  ${Media.below.tablet`
    text-align: center;
    padding-top: 20px;
  `}
`

const FooterLogo = styled.div`
  flex: 0 !important;
  width: 100%;
  ${Media.below.tablet`
      text-align:center;
    `}
`

const BottomFooter = styled.div`
  font-size: 0.9rem;
  text-align: center;
  padding: 1.5rem 0;
`

const Footer = ({ site = {}, meta = {} }) => (
  <StyledFooter>
    <Wrapper>
      <Flex>
        <SiteMeta>
          <p>
            Phone: {meta.acf.company_phone_number}
            <br />
            <br />
            Email: {meta.acf.company_email}
            <br />
            <br />
            Address: <br />
            {meta.acf.address_one}
            <br />
            {meta.acf.address_two}
          </p>
        </SiteMeta>
        <FooterLogo>
          <img
            src={footerimg}
            alt="Siferd Plumbing, Heating and Air Conditioning"
          />
          <SiteMeta>
            <center>
              <h4>Ohio License #{meta.acf.ohio_license} </h4>
            </center>
          </SiteMeta>
        </FooterLogo>
        <Bbb>
          <a
            id="bbblink"
            class="ruvtbam"
            href="https://www.bbb.org/us/oh/findlay/profile/plumber/siferd-plumbing-heating-air-conditioning-llc-0422-9001651"
            title="Siferd Plumbing, Heating, Air Conditioning LLC, Plumber, Findlay, OH"
            style={{
              display: "block",
              position: "relative",
              overflow: "hidden",
              width: "60px",
              height: "108px",
              margin: 0,
              padding: 0,
            }}
          >
            <img
              style={{ padding: 0, border: "none" }}
              id="bbblinkimg"
              src="https://seal-toledo.bbb.org/logo/ruvtbam/siferd-plumbing-heating-air-conditioning-9001651.png"
              width="120"
              height="108"
              alt="Siferd Plumbing, Heating, Air Conditioning LLC, Plumber, Findlay, OH"
            />
          </a>
        </Bbb>
      </Flex>
      <BottomFooter>
        &copy; {`${moment().format("YYYY")} `} {site.description}. All Rights
        Reserved
        <br />
        Developed By{" "}
        <a href="https://www.toddproductions.com/">Stanton Creative Media</a>
      </BottomFooter>
    </Wrapper>
  </StyledFooter>
)

export default Footer
