import React from "react"
import styled, { css } from "styled-components"
import { darken } from "polished"
import { applyStyleModifiers } from "styled-components-modifiers"
import {
  Wrapper,
  Color,
  Button,
  Media,
  ButtonLink,
  ButtonA,
} from "../../utilities"

const primaryButtonCSS = css`
  border-color: ${Color.blue};
  color: ${Color.blue} !important;
  &:hover {
    color: ${Color.white} !important;
    text-decoration: none !important;
  }
`

const dangerButtonCSS = css`
  border-color: ${Color.red};
  color: ${Color.red} !important;

  &:hover {
    color: ${Color.white} !important;
    text-decoration: none !important;
  }
`

const warningButtonCSS = css`
  border-color: ${Color.yellow};
  color: ${Color.yellow} !important;
  &:hover {
    color: ${Color.white} !important;
    text-decoration: none !important;
  }
`

const SECTION_MODIFIER = {
  primary: () => `
    h1, h2, h3, h4, h5 {
      color: ${Color.blue};
    }
    ${ButtonLink} {
      ${primaryButtonCSS}
    }
    ${Button} {
      ${primaryButtonCSS}
    }
  `,
  danger: () => `
    h1, h2, h3, h4, h5 {
    color: ${Color.red};

    ${ButtonLink} {
      ${dangerButtonCSS}
    }
  `,

  default: () => `
    background-color: ${darken(0.05, Color.white)};
    h1, h2, h3, h4, h5 {
    color: ${Color.red};

    ${ButtonLink} {
      ${dangerButtonCSS}
    }
  `,

  warning: () => `
    h1, h2, h3, h4, h5 {
    color: ${Color.yellow};

    ${Button} {
      ${warningButtonCSS}
    }
    ${ButtonLink} {
      ${warningButtonCSS}
    }
  `,
}

const SectionContainer = styled.section`
  padding: 3rem 0;
  background-size: cover;
  background-color: ${Color.white};

  h1 {
    text-align: center;
  }

  ${props =>
    props.background &&
    `
    background-image: url(${props.background});
  `}

  ${props =>
    props.minHeight &&
    `
    min-height: ${props.minHeight};
  `}

  h1, h2, h3, h4, h5 {
    margin-bottom: 2rem;
    line-height: 120%;
  }

  a:not(${ButtonLink}):not(${ButtonA}) {
    color: ${Color.red} !important;
  }

  ${applyStyleModifiers(SECTION_MODIFIER)};
`

const FlexBox = styled.div`
  max-width: 400px;
  width: 100%;
  background: ${Color.white};
  padding: 3rem;
  border-radius: 25px;
  text-align: center;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 120%;
    margin-bottom: 2rem;
  }

  ${Button}, ${ButtonA} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: -20px;
    display: inline-block;
  }
`

const FlexContainer = styled.div`
  display: flex
    ${props =>
      props.position === "left"
        ? `
    justify-content: flex-start;
  `
        : `
    justify-content: flex-end;
  `};

  ${Media.below.tablet`
    justify-content: center;
  `}
`

export const Section = ({
  children,
  background,
  position = "left",
  modifiers = [],
  flex = false,
  minHeight = null,
}) => {
  return (
    <SectionContainer
      background={background}
      modifiers={modifiers}
      minHeight={minHeight}
    >
      <Wrapper>
        {flex ? (
          <FlexContainer position={position}>
            <FlexBox>{children}</FlexBox>
          </FlexContainer>
        ) : (
          <div>{children}</div>
        )}
      </Wrapper>
    </SectionContainer>
  )
}
