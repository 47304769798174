import React from "react"
import styled from "styled-components"
import { Section } from "../Section"
import { Grid, Col, AlignCenter } from "../../utilities"

import licensed from "../../../images/licensed.png"
import upfront from "../../../images/upfront.png"

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Col} {
    text-align: center;
    flex: 1;
  }
`

export const ContactSection = ({ node }) => (
  <Section modifiers={"danger"}>
    <AlignCenter>
      <h1 dangerouslySetInnerHTML={{ __html: node.title }}></h1>
    </AlignCenter>
    <Grid>
      <Row>
        <Col>
          <img src={licensed} alt="Fully Licensed" />
          <img src={upfront} alt="Upfront Pricing" />
        </Col>
      </Row>
      <Col>
        <div dangerouslySetInnerHTML={{ __html: node.content }}></div>
      </Col>
    </Grid>
  </Section>
)
