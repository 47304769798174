import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { linearGradient } from "polished"

import Header from "../Header"
import Footer from "../Footer"

import { GlobalStyles, PopupContactForm } from "../../elements"
import { Color, ButtonLink, SEO } from "../../utilities"

const Body = styled.div`
  line-height: 200%;
  font-size: 18px;
  ${linearGradient({
    colorStops: [`${Color.primary} 0%`, `${Color.grey} 100%`],
    toDirection: "to right",
    fallback: Color.primary,
  })}

  a:not(${ButtonLink}) {
    color: ${Color.white};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 18px;
    line-height: 200%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`

export const fixContentLinks = content => {
  const regex = /^((http|https|ftp):\/\/)/
  if (regex.test(content)) {
    content = content.replace(
      process.env.GATSBY_API_URL,
      process.env.GATSBY_WEB_URL
    )
  }

  return content
}

const Layout = ({
  children,
  image,
  bodyRender,
  mobileBackground,
  title = null,
  description = null,
}) => (
  <StaticQuery
    query={graphql`
      query SiteDataQuery {
        wordpressSiteMetadata {
          name
          description
        }
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              id
              slug
              name
              items {
                object_slug
                title
                url
                wordpress_children {
                  title
                  object_slug
                }
              }
            }
          }
        }
        wordpressWpConfig {
          id
          title
          content
          acf {
            facebook
            twitter
            linkedin
            instagram
            youtube
            company_email
            company_phone_number
            ohio_license
            address_one
            address_two
          }
        }
      }
    `}
    render={({
      wordpressSiteMetadata,
      allWordpressWpApiMenusMenusItems,
      wordpressWpConfig,
    }) => (
      <>
        <SEO title={title} description={description} />
        <GlobalStyles />
        <PopupContactForm />
        <Header
          site={wordpressSiteMetadata}
          image={image}
          bodyRender={bodyRender}
          nav={allWordpressWpApiMenusMenusItems.edges[0].node.items}
          meta={wordpressWpConfig}
          mobileBackground={mobileBackground}
        />
        <Body>
          {typeof children === "function"
            ? children({ wordpressWpConfig })
            : children}
        </Body>
        <Footer site={wordpressWpConfig} meta={wordpressWpConfig} />
      </>
    )}
  ></StaticQuery>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
