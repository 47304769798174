import React, { useState, useEffect } from "react"
import styled from "styled-components"
import * as cookies from "js-cookie"

import { Modal, Color, Alert } from "../../utilities"
import { ContactForm } from "../ContactForm"

const BoxStyles = styled.div`
  h2 {
    text-align: center;
    color: ${Color.red};
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }
`

const closeModal = ({ toggle }) => {
  // Set Cookie
  cookies.set("pest_avoid", true, { expires: 1 })
  toggle(false)
}

const delayClose = ({ toggle }) => {
  // Set Cookie
  cookies.set("pest_avoid", true, { expires: 1 })
  // Delay Toggle
  setTimeout(() => {
    toggle(false)
  }, 2000)
}

export const PopupContactForm = () => {
  const [on, toggle] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const cookie = cookies.get("pest_avoid")
    if (cookie !== "true") {
      toggle(true)
    }
  }, [])

  return (
    <Modal on={on} toggle={() => closeModal({ toggle })}>
      <BoxStyles>
        <h2>Get A Free Quote Today!</h2>
        <p>Fill out this form and we will be in touch with your FREE quote.</p>
        {message ? (
          <Alert modifiers={["success"]}>
            Thank You! We Will Be In Touch With You Soon!
          </Alert>
        ) : (
          <ContactForm
            popup={true}
            postSubmit={() => {
              setMessage(true)
              delayClose({ toggle })
            }}
          />
        )}
      </BoxStyles>
    </Modal>
  )
}
