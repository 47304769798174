export const Color = {
  white: "#FFF",
  black: "#000",
  red: "#c52027",
  grey: "#687379",
  blue: "#009edb",
  yellow: "#ffc82f",
  font: "#363636",
  green: "#1ab512",
}
