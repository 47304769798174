import React from "react"

import styled from "styled-components"
import { Col, Color, Type, Media } from "../../utilities"

const profileWidth = "250px"

const TeamMemberStyle = styled.div`
  text-align: center;
  width: 100%;
  ${props =>
    props.isOwner &&
    `
    grid-column-start: 1;
    grid-column-end: 5;
  `}

  ${Media.below.tablet`
    ${props =>
      props.isOwner &&
      `
    grid-column-end: 2;
  `}
    
  `}
`

const TeamPic = styled.div`
  height: ${profileWidth};
  width: ${profileWidth};
  background-color: ${Color.grey};
  ${props =>
    props.pic &&
    `
    background-image: url(${props.pic});
  `}
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
`

const TeamName = styled.h3`
  margin-bottom: 0.75rem !important;
  ${Media.below.tablet`
    text-align: center;
  `}
`
const TeamTitle = styled.h4`
  color: ${Color.grey} !important;
  font-size: 1.2rem;
  margin-bottom: 1rem !important;
  ${Media.below.tablet`
    text-align: center;
  `}
`

const TeamBio = styled.div`
  font-family: ${Type.text};
  text-align: left;
  color: ${Color.grey} !important;
  p {
    font-size: 1rem !important;
  }

  ${Media.below.tablet`
    text-align: center;
  `}
`

const TeamGrid = styled.div`
  display: grid;
  ${props =>
    props.isOwner
      ? `
    grid-template-columns: ${profileWidth} auto;
     grid-column-gap: 3rem;
     `
      : `
      grid-template-columns: 1fr;
      `}

  width: 100%;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const InfoCol = styled.div`
  width: 100%;
  text-align: ${props => (props.isOwner ? `left` : `center`)};
  padding-bottom: 2rem;
`

export const TeamMember = ({ name, title, children, isOwner, pic }) => (
  <TeamMemberStyle isOwner={isOwner}>
    <TeamGrid isOwner={isOwner}>
      <Col>{pic && <TeamPic pic={pic} />}</Col>
      <InfoCol isOwner={isOwner}>
        <TeamName>{name}</TeamName>
        {title && <TeamTitle>{title}</TeamTitle>}
        <TeamBio>{children}</TeamBio>
      </InfoCol>
    </TeamGrid>
  </TeamMemberStyle>
)
