import React from "react"

import API, { returnData } from "../../API"
import {
  Form,
  Input,
  Mutation,
  ErrorAlert,
  Grid,
  Col,
  Button,
  Textarea,
  Radio,
  FormLabel,
} from "../../utilities"
import styled from "styled-components"

const inputs = {
  name: "",
  phone: "",
  address: "",
  email: "",
  help: "",
  description: "",
  date: "",
}

const FormGrid = styled(Grid)`
  ${props =>
    props.popup &&
    `
    column-gap: 1rem;
    row-gap: 0.5rem;
  `}
`

export const sendToWP = data =>
  API.post(`/submission`, { ...data }).then(returnData)

export const ContactForm = ({ popup = false, postSubmit }) => (
  <>
    <Mutation
      mutation={sendToWP}
      id="contactForm"
      postFunc={() => postSubmit()}
    >
      {(contactUs, { error, loading }) => (
        <Form state={inputs} required={["name", "help"]} onSubmit={contactUs}>
          {({ handleChange, state, isInvalid }) => (
            <>
              <FormGrid modifiers="gap" popup={popup}>
                <Col>
                  <Input name="name" onChange={handleChange} state={state.name}>
                    Your Name *
                  </Input>
                </Col>
                <Col>
                  <Input
                    name="email"
                    onChange={handleChange}
                    state={state.email}
                  >
                    Email
                  </Input>
                </Col>
                <Col>
                  <Input
                    name="address"
                    onChange={handleChange}
                    state={state.address}
                  >
                    Address
                  </Input>
                </Col>
                <Col>
                  <Input
                    name="phone"
                    onChange={handleChange}
                    state={state.phone}
                  >
                    Phone
                  </Input>
                </Col>
                <Col>
                  <FormLabel>What Can We Help With?</FormLabel>
                  <Radio
                    name="help"
                    data="Estimate"
                    value={state.help}
                    onChange={handleChange}
                  >
                    Estimate
                  </Radio>
                  <Radio
                    name="help"
                    data="Schedule"
                    value={state.help}
                    onChange={handleChange}
                  >
                    Schedule
                  </Radio>
                </Col>
                <Col>
                  <Input name="date" onChange={handleChange} state={state.date}>
                    Best date/time for appointment
                  </Input>
                </Col>
              </FormGrid>

              {!popup && (
                <Textarea
                  minHeight="200px"
                  state={state.description}
                  name="description"
                  onChange={handleChange}
                >
                  Description of service needed
                </Textarea>
              )}

              <ErrorAlert error={error} />

              {/* {error === null && result !== null && (
                <Alert modifiers={["success"]}>
                  Please check your email for Webinar login details. Thank you!
                </Alert>
              )} */}

              <Button
                modifiers={["block", "danger"]}
                disabled={isInvalid || loading}
                type="submit"
              >
                {loading ? "Loading..." : "Get Free Quote"}
              </Button>
            </>
          )}
        </Form>
      )}
    </Mutation>
  </>
)
