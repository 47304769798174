import React from "react";
import styled from "styled-components";
import { lighten } from "polished";
import { Color } from "../Color";

export const StyledSection = styled.div``;

export const SectionHeader = styled.div`
  padding: 0.5rem 1rem;
  background: ${lighten(0.6, Color.grey)};
  border-left: 5px solid ${Color.primary};
  color: ${Color.primary};
  font-size: 1.1rem;
  font-weight: 700;
`;

export const SectionBody = styled.div`
  padding: 1rem;
`;

export const Section = ({ header, children, ...props }) => (
  <StyledSection>
    {header && <SectionHeader>{header}</SectionHeader>}
    <SectionBody>{children}</SectionBody>
  </StyledSection>
);
