import React from "react"
import styled from "styled-components"

import { Grid, Color, ButtonLink, Media, Wrapper, Col } from "../../utilities"

import licensed from "../../../images/licensed.png"
import upfront from "../../../images/upfront.png"

const HeaderTitle = styled.div`
  padding-top: 2rem;

  h1 {
    color: ${Color.white};
    margin: 0;
    line-height: 150%;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
`

const HeaderGrid = styled(Grid)`
  padding-top: 2rem;
  min-height: 400px;

  ${ButtonLink} {
    text-decoration: none;
    margin: 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 3rem;

  img {
    height: 90px;
  }

  ${Media.below.tablet`
    display: none;
  `}
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
`

const CenterBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
`

export const HeaderRender = ({ children }) => (
  <Wrapper>
    <HeaderGrid modifiers={["gap"]}>
      <HeaderTitle>
        <TitleGrid>
          <CenterBox>
            <h1>{children}</h1>
          </CenterBox>
        </TitleGrid>
      </HeaderTitle>
      <Col>
        <Row>
          <img src={licensed} alt="Fully Licensed" />
          <img src={upfront} alt="Upfront Pricing" />
        </Row>
      </Col>
    </HeaderGrid>
  </Wrapper>
)
