import React, { Fragment } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { darken } from "polished"

import {
  Color,
  Wrapper,
  Type,
  Media,
  Button,
  Grid,
  Col,
  Toggle,
} from "../../utilities"
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

import logoimg from "../../../images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NavButton = styled(Button)`
  position: relative;
  text-decoration: none;
`

const NavWrapper = styled(Wrapper)`
  padding: 0;
  margin: 0;
  height: auto !important;

  ${NavButton} {
    margin: 0 !important;
    width: 100%;
    border-radius: 0;
    margin-top: 10px !important;
    display: none;

    ${Media.below.tablet`
      display: block;
    `}
  }

  ${Media.below.tablet`
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
  `}
`

const HeaderContainer = styled.div`
  font-family: ${Type.header};
  background-size: cover;
  background-color: ${Color.red};
  ${props =>
    props.image &&
    `
      background-image: url(${props.image});
  `}

  ${Media.below.tablet`
    ${props =>
      props.mobileBackground &&
      `
      background-image: none;
    `}
  `}
`

const TopHeader = styled.div`
  background: ${Color.red};
  color: ${Color.white};
  font-size: 1.3em;
  padding: 5px;
  text-align: center;
`

const BottomHeader = styled.div`
  background: ${Color.white};
  font-size: 1.3em;
  text-align: center;
  margin-top: 45px;

  ${Media.below.tablet`
    margin-top: 0;
  `}
`

const Logo = styled(Link)`
  flex: 0 !important;
  width: 100%;
  top: -55%;
  position: relative;
  height: 20px;
  z-index: 8000;

  ${Media.below.tablet`
    height: auto;
    padding-top: 1rem;
  `}
`

const TopHeaderGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr;
  ${Media.below.tablet`
    display: block;
  `}
`

const BottomHeaderGrid = styled(Grid)`
  grid-template-columns: 1fr 2fr;

  ${Media.below.tablet`
    display: block;
  `}
`

const NavDropdown = styled.ul`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 122px !important;
  width: 100%;
  display: none;
  z-index: 5000;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

  ${Media.below.tablet`
    display: block;
    position: relative;
    left: auto;
    top: auto;
    margin-top: 0 !important;

    ${Wrapper} {
      width: 100%;
      margin: 0;
    }

  `};
`

const NavItem = styled.li`
  color: ${Color.white};
  list-style: none;
  flex: 1;

  a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: ${Color.red};

    &:hover {
      cursor: pointer;
      background-color: ${Color.red};
      color: ${Color.white};
    }
  }

  &:hover {
    ${NavDropdown} {
      display: block;
      flex: 20%;
      padding: 0px;
      justify-content: space-between;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  ${Media.below.tablet`
    display: block;
  `}
`

const NavUL = styled.ul`
  padding-left: 0px;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${Media.below.tablet`
    ${props => (props.on ? "display: block;" : "display: none;")};
  `}
`

const DDTitle = styled.div`
  color: ${Color.white};
  padding: 0.5rem;
  background-color: ${Color.red};
  width: 100%;
  display: block;
  transition: all 0.3s ease;
`

const DDLink = styled(Link)`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  height: 250px;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex !important;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  padding: 0 !important;

  ${Media.below.tablet`
    margin: 0;
    height: auto;
    background: none;
    color: ${Color.white} !important;
  `}

  &:hover {
    ${DDTitle} {
      background-color: ${darken(0.1, Color.red)};
    }
  }

  ${props => props.image && `background-image: url(${props.image})`}
`

const SocialCol = styled(Col)`
  a {
    color: ${Color.white};
    display: inline-block;
    margin-right: 10px;

    &:hover {
      color: ${darken(0.2, Color.red)};
    }
  }
  ${Media.below.tablet`
    display: none;
  `}

  .sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`

const socialArr = [
  {
    key: "facebook",
    icon: faFacebook,
  },
  {
    key: "twitter",
    icon: faTwitter,
  },
  {
    key: "linkedin",
    icon: faLinkedin,
  },
  {
    key: "instagram",
    icon: faInstagram,
  },
  {
    key: "youtube",
    icon: faYoutube,
  },
]

const renderSubItem = ({ pages = [], slug }) => {
  const match = pages.find(page => page.node.slug === slug)
  return match ? match.node.featured_media.source_url : null
}

const Header = ({
  site = {},
  meta = {},
  image = null,
  mobileBackground = false,
  nav = [],
  bodyRender = () => <div></div>,
}) => {
  return (
    <HeaderContainer image={image} mobileBackground={mobileBackground}>
      <TopHeader>
        <Wrapper>
          <TopHeaderGrid>
            <Col></Col>
            <Col>For Service Call: {meta.acf.company_phone_number}</Col>
            <SocialCol>
              {socialArr.map(icon => (
                <Fragment>
                  {typeof meta.acf[icon.key] !== "undefined" &&
                    meta.acf[icon.key] !== "" && (
                      <a href={meta.acf[icon.key]} target="_blank">
                        <FontAwesomeIcon icon={icon.icon} />
                        <span className="sr-only">{icon.key}</span>
                      </a>
                    )}
                </Fragment>
              ))}
            </SocialCol>
          </TopHeaderGrid>
        </Wrapper>
      </TopHeader>
      <BottomHeader>
        <Wrapper>
          <BottomHeaderGrid>
            <Logo to="/" title={`${site.name} Home Page`}>
              <img
                src={logoimg}
                alt="Siferd Plumbing, Heating and Air Conditioning"
              />
            </Logo>
            <NavWrapper>
              <Toggle>
                {({ on, toggle }) => (
                  <Fragment>
                    <NavButton
                      modifiers={["danger", "large", "block"]}
                      onClick={toggle}
                    >
                      Click For Navigation
                    </NavButton>
                    <NavUL on={on}>
                      <StaticQuery
                        query={graphql`
                          query NavQuery {
                            allWordpressPage {
                              edges {
                                node {
                                  slug
                                  featured_media {
                                    source_url
                                  }
                                }
                              }
                            }
                          }
                        `}
                        render={({ allWordpressPage }) => (
                          <Fragment>
                            {nav.map(link => (
                              <NavItem>
                                {link.url === "#" ? (
                                  <a
                                    role="button"
                                    onClick={e => e.preventDefault()}
                                  >
                                    {link.title}
                                  </a>
                                ) : (
                                  <Link to={`/${link.object_slug}/`}>
                                    {link.title}
                                  </Link>
                                )}
                                {link.wordpress_children !== null && (
                                  <NavDropdown>
                                    <Wrapper>
                                      <Row>
                                        {link.wordpress_children.map(
                                          sublink => (
                                            <DDLink
                                              image={renderSubItem({
                                                pages: allWordpressPage.edges,
                                                slug: sublink.object_slug,
                                              })}
                                              to={`/${sublink.object_slug}/`}
                                            >
                                              <DDTitle>{sublink.title}</DDTitle>
                                            </DDLink>
                                          )
                                        )}
                                      </Row>
                                    </Wrapper>
                                  </NavDropdown>
                                )}
                              </NavItem>
                            ))}
                          </Fragment>
                        )}
                      />
                    </NavUL>
                  </Fragment>
                )}
              </Toggle>
            </NavWrapper>
          </BottomHeaderGrid>
        </Wrapper>
      </BottomHeader>

      {bodyRender()}
    </HeaderContainer>
  )
}

export default Header
